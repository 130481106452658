(function ($) {

    let menu_trap = focusTrap.createFocusTrap('.header-menu');
    const $body = $('body');

    console.log(menu_trap);

    $('.mobile-menu-open').on('click', () => {
        $('.header-menu').addClass('open');
        $('#menu-header-menu').show();
        $body.addClass('menu-open');
        menu_trap.activate();

    });

    $('.mobile-menu-close').on('click', () => {
        $('.header-menu').removeClass('open');
        $('#menu-header-menu').hide();
        $body.removeClass('menu-open');
        menu_trap.deactivate();
    });

    $(window).on('load resize', function() {
        if (window.innerWidth > 991) {
            const $subMenuWrapper = $('.sub-menu-toggle').next('.sub-menu-wrapper');
            $subMenuWrapper.slideUp(300, function () {
                $subMenuWrapper.removeClass('expanded');
            });
            $('.sub-menu-toggle').removeClass('toggled');
            $('.header-menu').removeClass('open');
            $('#menu-header-menu').hide();
            $body.removeClass('menu-open');
        }
    });
    

    $('.sub-menu-toggle').on('click', function () {
        const $button = $(this);
        const $subMenuWrapper = $button.next('.sub-menu-wrapper');

        if ($subMenuWrapper.hasClass('expanded')) {
            // Collapse submenu
            $subMenuWrapper.slideUp(300, function () {
                $subMenuWrapper.removeClass('expanded');
            });
            $button.removeClass('toggled');
        } else {
            // Expand submenu
            $subMenuWrapper.slideDown(300, function () {
                $subMenuWrapper.addClass('expanded');
            });
            $button.addClass('toggled');
        }
    });


    // Submenus
    const set_submenu_position = () => {
        let header_height = $('.header').height();
        $('#menu-header-main .menu-item > .sub-menu-wrapper').css({ top: header_height + 'px' });
        //     $('#menu-header-main .menu-item > .sub-menu-wrapper').each(function () {
        //         let $parent = $(this).closest('.menu-item');
        //         let left = $parent.offset().left + 25;
        //         $(this).find('.sub-menu').css({ left: left + 'px' });
        //     });
    }
    set_submenu_position();
    $(window).resize(set_submenu_position);

})(jQuery);
